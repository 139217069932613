import * as React from "react"
import Layout from "../components/layout2023"
import SeoNoIndex from "../components/seo-no-index-ablyft"
import TestimonialsPage from "../components/testimonials-new/testimonials"

const Testimonials = () => (
  <Layout nav={"hidden"}>
    <SeoNoIndex title="Testimonials" index={false} follow={false} />
    <TestimonialsPage wrapperClass={"testimonials-wrap testimonials-2023"} />
  </Layout>
)

export default Testimonials
