import React from "react"
import { Link } from "gatsby"
import SiteNavigation from "../nav-new/nav"
import YouTube from "react-youtube"
import Image from "../image-component/image-new"
import Modal from "../modal/modal"
import "./testimonials.scss"

const pageContent = {
  heroHeading: "Testimonials",
  heroSubheading:
    "See how high-growth brands are beating plans, crushing competitors and having fun doing it.",
  heroImage: "testimonials_hero_image.png",
  testimonials: [
    {
      id: "okta",
      logo: "okta-logo-2023.webp",
      blueLogo: {
        fallBackImage: "okta_blue_logo.png",
        responsiveImages: [
          {
            imageName: "okta_blue_logo.png",
            minBreakpoint: "992px",
          },
          {
            imageName: "okta_blue_logo_tablet.png",
            minBreakpoint: "768px",
          },
          {
            imageName: "okta_blue_logo_mobile.png",
            minBreakpoint: "320px",
          },
        ],
      },
      alt: "Okta Logo",
      testimonialDescription:
        "Through a number of page redesign tests we’ve seen test lifts between 40-90%.",
      personName: "Rodolfo Yiu",
      personDesignation: "Senior Manager, Digital Marketing",
      ctaURL: "",
      ctaText: "See time doctor Case Study",
      videoPlaceholder: {
        fallBackImage: "rodolfo-yiu.webp",
        responsiveImages: [
          {
            imageName: "rodolfo-yiu.webp",
            minBreakpoint: "768px",
          },
          {
            imageName: "rodolfo-yiu-mobile.webp",
            minBreakpoint: "320px",
          },
        ],
      },
      placeholderAlt: "Okta Video",
      yutubeButton: "play-youtube-2023.svg",
      videoLength: "6 min",
      videoID: "xv3XXP_Tosg",
    },
    {
      id: "servicemax",
      logo: "servicemax-logo-2023.webp",
      blueLogo: {
        fallBackImage: "servicemax_blue_logo.png",
        responsiveImages: [
          {
            imageName: "servicemax_blue_logo.png",
            minBreakpoint: "992px",
          },
          {
            imageName: "servicemax_blue_logo_tablet.png",
            minBreakpoint: "768px",
          },
          {
            imageName: "servicemax_blue_logo_mobile.png",
            minBreakpoint: "320px",
          },
        ],
      },
      alt: "ServiceMax Logo",
      testimonialDescription:
        "We had aggressive KPI targets, and I’d have to say they’ve delivered on all points. On our landing pages, we’ve seen over a 30% conversion rate increase. We have a lot of exciting initiatives for the next year and Spiralyze is at the core of a lot of these initiatives.",
      personName: "Pat Oldenburg",
      personDesignation: "Vice President, Demand Marketing and Operations",
      ctaURL: "",
      ctaText: "See ServiceMax Case Study",
      videoPlaceholder: {
        fallBackImage: "pat-oldenburg.webp",
        responsiveImages: [
          {
            imageName: "pat-oldenburg.webp",
            minBreakpoint: "768px",
          },
          {
            imageName: "pat-oldenburg-mobile.webp",
            minBreakpoint: "320px",
          },
        ],
      },
      placeholderAlt: "ServiceMax Video",
      yutubeButton: "play-youtube-2023.svg",
      videoLength: "4 min",
      videoID: "9LBXrVZsiXA",
    },
    {
      id: "idealimage",
      logo: "idealimage-logo-2023.webp",
      blueLogo: {
        fallBackImage: "ideal_image_blue_logo.png",
        responsiveImages: [
          {
            imageName: "ideal_image_blue_logo.png",
            minBreakpoint: "992px",
          },
          {
            imageName: "ideal_image_blue_logo_tablet.png",
            minBreakpoint: "768px",
          },
          {
            imageName: "idealimage_blue_logo_mobile.png",
            minBreakpoint: "320px",
          },
        ],
      },
      alt: "IdealImage Logo",
      testimonialDescription:
        "Spiralyze's impact has been tremendous. In about 3 months, we improved our laser hair removal landing page conversion rates by over 30% which has an amazing impact on business.",
      personName: "Brian Stetter",
      personDesignation: "VP Digital Marketing",
      ctaURL: "",
      ctaText: "See Ideal image Case Study",
      videoPlaceholder: {
        fallBackImage: "brian-stetter.webp",
        responsiveImages: [
          {
            imageName: "brian-stetter.webp",
            minBreakpoint: "768px",
          },
          {
            imageName: "brian-stetter-mobile.webp",
            minBreakpoint: "320px",
          },
        ],
      },
      placeholderAlt: "IdealImage Video",
      yutubeButton: "play-youtube-2023.svg",
      videoLength: "3 min",
      videoID: "m4mn1DZIJBM",
    },
    {
      id: "workday",
      logo: "workday-logo-2023.webp",
      blueLogo: {
        fallBackImage: "workday_blue_logo.png",
        responsiveImages: [
          {
            imageName: "workday_blue_logo.png",
            minBreakpoint: "992px",
          },
          {
            imageName: "workday_blue_logo_tablet.png",
            minBreakpoint: "768px",
          },
          {
            imageName: "workday_blue_logo_mobile.png",
            minBreakpoint: "320px",
          },
        ],
      },
      alt: "WorkDay Logo",
      testimonialDescription:
        "Working with Spiralyze, for Adaptive Insights, has been fantastic. They have truly been an extension of our team, they have helped us improve our conversion rates, we have driven more leads, we have beaten plan, and we have helped build a lot of pipeline.",
      personName: "Michael Freeman",
      personDesignation: "VP Demand Marketing",
      ctaURL: "",
      ctaText: "See Workday Case Study",
      videoPlaceholder: {
        fallBackImage: "michael-freeman.webp",
        responsiveImages: [
          {
            imageName: "michael-freeman.webp",
            minBreakpoint: "768px",
          },
          {
            imageName: "michael-freeman-mobile.webp",
            minBreakpoint: "320px",
          },
        ],
      },
      placeholderAlt: "WorkDay Video",
      yutubeButton: "play-youtube-2023.svg",
      videoLength: "2 min",
      videoID: "2uQsuv_1tX0",
    },
    {
      id: "podium",
      logo: "podium-logo-2023.webp",
      blueLogo: {
        fallBackImage: "podium_blue_logo.png",
        responsiveImages: [
          {
            imageName: "podium_blue_logo.png",
            minBreakpoint: "992px",
          },
          {
            imageName: "podium_blue_logo_tablet.png",
            minBreakpoint: "768px",
          },
          {
            imageName: "podium_blue_logo_mobile.png",
            minBreakpoint: "320px",
          },
        ],
      },
      alt: "Podium Logo",
      testimonialDescription:
        "If we even improve conversion on our page by 1% or 2%, it would pay for the Spiralyze contract. Most of the tests we've run have improved conversion rates by 20-50% and they have tests mapped out for the rest of the year. It is not even close to what we could've done on our own and is significantly better.",
      personName: "Matt Boyce",
      personDesignation: "Sr. Director of Demand Generation",
      ctaURL: "",
      ctaText: "See time doctor Case Study",
      videoPlaceholder: {
        fallBackImage: "matt-boyce.webp",
        responsiveImages: [
          {
            imageName: "matt-boyce.webp",
            minBreakpoint: "768px",
          },
          {
            imageName: "matt-boyce-mobile.webp",
            minBreakpoint: "320px",
          },
        ],
      },
      placeholderAlt: "Podium Video",
      yutubeButton: "play-youtube-2023.svg",
      videoLength: "5 min",
      videoID: "G-55Zm8r57s",
    },
    {
      id: "rstudio",
      logo: "rstudio-logo-2023.webp",
      blueLogo: {
        fallBackImage: "rstudio_blue_logo.png",
        responsiveImages: [
          {
            imageName: "rstudio_blue_logo.png",
            minBreakpoint: "992px",
          },
          {
            imageName: "rstudio_blue_logo_tablet.png",
            minBreakpoint: "768px",
          },
          {
            imageName: "rstudio_blue_logo_mobile.png",
            minBreakpoint: "320px",
          },
        ],
      },
      alt: "RStudio Logo",
      testimonialDescription:
        "Spiralyze really jumpstarted the whole website testing program and process for us. They increased exponentially our qualified pro-conversions. More importantly, they acted as an extension of our team.",
      personName: "Bill Carney",
      personDesignation: "Director of Marketing",
      ctaURL: "",
      ctaText: "See The R-STUDIO Case Study",
      videoPlaceholder: {
        fallBackImage: "bill-carney.webp",
        responsiveImages: [
          {
            imageName: "bill-carney.webp",
            minBreakpoint: "768px",
          },
          {
            imageName: "bill-carney-mobile.webp",
            minBreakpoint: "320px",
          },
        ],
      },
      placeholderAlt: "R-Studio Video",
      yutubeButton: "play-youtube-2023.svg",
      videoLength: "2 min",
      videoID: "8RnNZpui6vw",
    },
    {
      id: "five9",
      logo: "five9-logo-2023.webp",
      blueLogo: {
        fallBackImage: "five9_blue_logo.png",
        responsiveImages: [
          {
            imageName: "five9_blue_logo.png",
            minBreakpoint: "992px",
          },
          {
            imageName: "five9_blue_logo_tablet.png",
            minBreakpoint: "768px",
          },
          {
            imageName: "five9_blue_logo_mobile.png",
            minBreakpoint: "320px",
          },
        ],
      },
      alt: "Five9 Logo",
      testimonialDescription:
        "Spiralyze helps us turn thousands of website visitors into leads for our sales force. They have tremendous experience and great suggestions.",
      personName: "Jason Yang",
      personDesignation: "VP Digital Marketing",
      ctaURL: "",
      ctaText: "See THE FIVE9 Case Study",
      videoPlaceholder: {
        fallBackImage: "jason-yang.webp",
        responsiveImages: [
          {
            imageName: "jason-yang.webp",
            minBreakpoint: "768px",
          },
          {
            imageName: "jason-yang-mobile.webp",
            minBreakpoint: "320px",
          },
        ],
      },
      placeholderAlt: "Five9 Video",
      yutubeButton: "play-youtube-2023.svg",
      videoLength: "2 min",
      videoID: "YdkdALKm37w",
    },
    {
      id: "progress",
      logo: "progress-logo-2023.webp",
      blueLogo: {
        fallBackImage: "progress_blue_logo.png",
        responsiveImages: [
          {
            imageName: "progress_blue_logo.png",
            minBreakpoint: "992px",
          },
          {
            imageName: "progress_blue_logo_tablet.png",
            minBreakpoint: "768px",
          },
          {
            imageName: "progress_blue_logo_mobile.png",
            minBreakpoint: "320px",
          },
        ],
      },
      alt: "Progress Logo",
      testimonialDescription:
        "We saw a 125% increase in conversion rate for demo requests.",
      personName: "Megan Gouveia",
      personDesignation:
        "Sr. Manager Digital Marketing | Personalization & Optimization",
      ctaURL: "",
      ctaText: "See Progress Case Study",
      videoPlaceholder: {
        fallBackImage: "megan-gouveia.webp",
        responsiveImages: [
          {
            imageName: "megan-gouveia.webp",
            minBreakpoint: "768px",
          },
          {
            imageName: "megan-gouveia-mobile.webp",
            minBreakpoint: "320px",
          },
        ],
      },
      placeholderAlt: "Progress Video",
      yutubeButton: "play-youtube-2023.svg",
      videoLength: "3 min",
      videoID: "oETkNoINKCQ",
    },
    {
      id: "timedoctor",
      logo: "timedoctor-logo-2023.webp",
      blueLogo: {
        fallBackImage: "timedoctor_blue_logo.png",
        responsiveImages: [
          {
            imageName: "timedoctor_blue_logo.png",
            minBreakpoint: "992px",
          },
          {
            imageName: "timedoctor_blue_logo_tablet.png",
            minBreakpoint: "768px",
          },
          {
            imageName: "timedoctor_blue_logo_mobile.png",
            minBreakpoint: "320px",
          },
        ],
      },
      alt: "TimeDoctor Logo",
      testimonialDescription:
        "Now 50% more is actually converting and getting revenue for us so it’s absolutely huge.",
      personName: "Rob Rawson",
      personDesignation: "Chief Executive Officer",
      ctaURL: "",
      ctaText: "See time doctor Case Study",
      videoPlaceholder: {
        fallBackImage: "rob-rawson.webp",
        responsiveImages: [
          {
            imageName: "rob-rawson.webp",
            minBreakpoint: "768px",
          },
          {
            imageName: "rob-rawson-mobile.webp",
            minBreakpoint: "320px",
          },
        ],
      },
      placeholderAlt: "TimeDoctor Video",
      yutubeButton: "play-youtube-2023.svg",
      videoLength: "1 min",
      videoID: "WridNRgdLS8",
    },
    {
      id: "digitalguardian",
      logo: "dg-logo-2023.webp",
      blueLogo: {
        fallBackImage: "digitalguardian_blue_logo.png",
        responsiveImages: [
          {
            imageName: "digitalguardian_blue_logo.png",
            minBreakpoint: "992px",
          },
          {
            imageName: "digital_guardian_blue_logo_tablet.png",
            minBreakpoint: "768px",
          },
          {
            imageName: "digitalguardian_blue_logo_mobile.png",
            minBreakpoint: "320px",
          },
        ],
      },
      alt: "Digital Guardian Logo",
      testimonialDescription:
        "For all of 2020, we actually increased our year-over-year website conversion rate by 50%, and we not only increased the conversion volume but the conversion quality.",
      personName: "Brian Mullins",
      personDesignation: "VP, Digital and Product Marketing",
      ctaURL: "",
      ctaText: "See time doctor Case Study",
      videoPlaceholder: {
        fallBackImage: "brian-mullins.webp",
        responsiveImages: [
          {
            imageName: "brian-mullins.webp",
            minBreakpoint: "768px",
          },
          {
            imageName: "brian-mullins-mobile.webp",
            minBreakpoint: "320px",
          },
        ],
      },
      placeholderAlt: "Digital Guardian Video",
      yutubeButton: "play-youtube-2023.svg",
      videoLength: "3 min",
      videoID: "cP3CWxHmGSM",
    },
    {
      id: "latka",
      logo: "latka-logo-2023.webp",
      blueLogo: {
        fallBackImage: "latka_blue_logo.png",
        responsiveImages: [
          {
            imageName: "latka_blue_logo.png",
            minBreakpoint: "992px",
          },
          {
            imageName: "latka_blue_logo_tablet.png",
            minBreakpoint: "768px",
          },
          {
            imageName: "latka_blue_logo_mobile.png",
            minBreakpoint: "320px",
          },
        ],
      },
      alt: "Nathan Latka Logo",
      testimonialDescription:
        "The impact that Spiralyze has had has basically been going from $10K-20K per month in sales on the magazine landing page to $30K-50K in sales per month on the landing page. It’s a material difference, and I’m excited to give them more and larger projects in the future.",
      personName: "Nathan Latka",
      personDesignation: "Founder, Author, & Investor",
      ctaURL: "",
      ctaText: "See time doctor Case Study",
      videoPlaceholder: {
        fallBackImage: "nathan-latka.webp",
        responsiveImages: [
          {
            imageName: "nathan-latka.webp",
            minBreakpoint: "768px",
          },
          {
            imageName: "nathan-latka-mobile.webp",
            minBreakpoint: "320px",
          },
        ],
      },
      placeholderAlt: "Nathan Latka Video",
      yutubeButton: "play-youtube-2023.svg",
      videoLength: "6 min",
      videoID: "mrLM14toyXs",
    },
    {
      id: "promscent",
      logo: "promscent-logo-2023.webp",
      blueLogo: {
        fallBackImage: "promscent_blue_logo.png",
        responsiveImages: [
          {
            imageName: "promscent_blue_logo.png",
            minBreakpoint: "992px",
          },
          {
            imageName: "promscent_blue_logo_tablet.png",
            minBreakpoint: "768px",
          },
          {
            imageName: "promscent_blue_logo_mobile.png",
            minBreakpoint: "320px",
          },
        ],
      },
      alt: "Promescent Logo",
      testimonialDescription:
        "They got up 3 tests in a matter of 3 weeks and one of those ended up winning by 30%.",
      personName: "Ryan Kellner",
      personDesignation: "Director of Marketing",
      ctaURL: "",
      ctaText: "See time doctor Case Study",
      videoPlaceholder: {
        fallBackImage: "ryan-kellner.webp",
        responsiveImages: [
          {
            imageName: "ryan-kellner.webp",
            minBreakpoint: "768px",
          },
          {
            imageName: "ryan-kellner-mobile.webp",
            minBreakpoint: "320px",
          },
        ],
      },
      placeholderAlt: "Promescent Video",
      yutubeButton: "play-youtube-2023.svg",
      videoLength: "4 min",
      videoID: "zUm4ipoEr90",
    },
    {
      id: "ubersuggest",
      logo: "ubersuggest-logo-2023.webp",
      blueLogo: {
        fallBackImage: "ubersuggest_blue_logo.png",
        responsiveImages: [
          {
            imageName: "ubersuggest_blue_logo.png",
            minBreakpoint: "992px",
          },
          {
            imageName: "ubersuggest_blue_logo_tablet.png",
            minBreakpoint: "768px",
          },
          {
            imageName: "ubersuggest_blue_logo_mobile.png",
            minBreakpoint: "320px",
          },
        ],
      },
      alt: "Ubersuggest Logo",
      testimonialDescription:
        "Spiralyze has run over 60 tests on Ubersuggest. My revenue has doubled. It has been well worth it!",
      personName: "Neil Patel",
      personDesignation: "Founder",
      ctaURL: "",
      ctaText: "See time doctor Case Study",
      videoPlaceholder: {
        fallBackImage: "neil-patel.webp",
        responsiveImages: [
          {
            imageName: "neil-patel.webp",
            minBreakpoint: "768px",
          },
          {
            imageName: "neil-patel-mobile.webp",
            minBreakpoint: "320px",
          },
        ],
      },
      placeholderAlt: "Ubersuggest Video",
      yutubeButton: "play-youtube-2023.svg",
      videoLength: "4 min",
      videoID: "rvRAwbs_s48",
    },
    {
      id: "nurturelife",
      logo: "nurture-life-logo-2023.webp",
      blueLogo: {
        fallBackImage: "logo-nurture-life-blue.png",
        responsiveImages: [
          {
            imageName: "logo-nurture-life-blue.png",
            minBreakpoint: "992px",
          },
          {
            imageName: "logo-nurture-life-blue-tablet.png",
            minBreakpoint: "768px",
          },
          {
            imageName: "logo-nurture-life-blue-mobile.png",
            minBreakpoint: "320px",
          },
        ],
      },
      alt: "Nurture Life Logo",
      testimonialDescription:
        "Spiralyze has had a really big impact on Nurture Life. When we first started working with them our conversion rate was relatively flat and since the beginning of our engagement, we've seen a steady upward increase.",
      personName: "Amy Rose Carle",
      personDesignation: "Director of Operations Excellence",
      ctaURL: "",
      ctaText: "",
      videoPlaceholder: {
        fallBackImage: "amy-rose-carle.webp",
        responsiveImages: [
          {
            imageName: "amy-rose-carle.webp",
            minBreakpoint: "768px",
          },
          {
            imageName: "amy-rose-carle-mobile.webp",
            minBreakpoint: "320px",
          },
        ],
      },
      placeholderAlt: "Nurture Life Video",
      yutubeButton: "play-youtube-2023.svg",
      videoLength: "2 min",
      videoID: "_JuuAIc8jyc",
    },
    {
      id: "caroo",
      logo: "caroo-logo-2023.webp",
      blueLogo: {
        fallBackImage: "logo-caroo-blue.png",
        responsiveImages: [
          {
            imageName: "logo-caroo-blue.png",
            minBreakpoint: "992px",
          },
          {
            imageName: "logo-caroo-blue-tablet.png",
            minBreakpoint: "768px",
          },
          {
            imageName: "logo-caroo-blue-mobile.png",
            minBreakpoint: "320px",
          },
        ],
      },
      alt: "Caroo Logo",
      testimonialDescription:
        "Spiralyze professionalized our CRO testing process which gave us a higher conversion rate through our funnels, giving us more MQLs, more leads to our sales team, and more revenue.",
      personName: "Andy Mackensen",
      personDesignation: "Co-Founder & CMO",
      ctaURL: "",
      ctaText: "",
      videoPlaceholder: {
        fallBackImage: "andy-mackensen.webp",
        responsiveImages: [
          {
            imageName: "andy-mackensen.webp",
            minBreakpoint: "768px",
          },
          {
            imageName: "andy-mackensen-mobile.webp",
            minBreakpoint: "320px",
          },
        ],
      },
      placeholderAlt: "Caroo Video",
      yutubeButton: "play-youtube-2023.svg",
      videoLength: "3 min",
      videoID: "gDLewKa32Sg",
    },
  ],
}
export default function Testimonials(props) {
  return (
    <span className={props.wrapperClass}>
      <section className={"hero-testimonials"}>
        <SiteNavigation />
        <div className="hero-container">
          <div className="hero-wrap">
            <div className="hero-heading">Testimonials</div>
            <div className="hero-subheading">
              See how high-growth brands are beating plans, crushing competitors
              and having fun doing it.
            </div>
          </div>
        </div>
      </section>
      <section className="testimonials-section">
        <div className="container">
          <div className="testimonials-content">
            {pageContent.testimonials.map((item, index) => {
              return (
                <div
                  className={
                    index % 2 ? "testimonial-bg flipped" : "testimonial-bg"
                  }
                >
                  <div
                    className={
                      index % 2 ? "testimonial flipped" : "testimonial"
                    }
                    key={index}
                    id={item.id}
                  >
                    <div className="content">
                      <Image
                        sourceFolder={"testimonials"}
                        className="logo"
                        imgName={item.logo}
                        alt={item.alt}
                      />
                      <p className="text">{item.testimonialDescription}</p>
                      <div className="custom_row">
                        <div className="left-content">
                          <b className="name">{item.personName}</b>
                          <div className="designation">
                            {item.personDesignation}
                          </div>
                        </div>
                        {/* <Button href="/" outlined="true" color="red">{item.ctaText}</Button> */}
                      </div>
                    </div>
                    <div className="video-wrapper">
                      <Modal
                        modalButton={
                          <span style={{ cursor: "pointer" }}>
                            <Image
                              sourceFolder={"testimonials"}
                              className="video"
                              imgName={item.videoPlaceholder.responsiveImages}
                              fallBackImage={
                                item.videoPlaceholder.fallBackImage
                              }
                              alt={item.placeholderAlt}
                            />
                            <button className="youtube-button">
                              <Image
                                sourceFolder={"testimonials"}
                                imgName={item.yutubeButton}
                                alt={"Youtube Button"}
                              />
                            </button>
                            <div className="testimonial-play-btn d-flex">
                              <img
                                width="16px"
                                height="16px"
                                alt="play icon"
                                className="play-icon"
                                src="https://res.cloudinary.com/spiralyze/image/upload/spiralyze2022/testimonials/play-testimonial.svg"
                              />
                              {item.videoLength}
                            </div>
                          </span>
                        }
                        modalContent={
                          <div className="iframe-wrap">
                            <YouTube videoId={item.videoID} />
                          </div>
                        }
                      />
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      </section>
    </span>
  )
}
